import React from "react"
import styled from 'styled-components'
import TextBlock from "../components/textBlock"
import Contact from "../components/contact"

import {Fade} from 'react-reveal'

const MainVideo = styled.section`
  width: 100%;
  @media (min-width: 1366px){
    height: 100vh;
  }
`;

const PointSection = styled.section`
  width: 100%;
  margin: 0 auto;
  padding: 0 30px 50px;
  > div:first-child {
    width: 100%;
    text-align: center;
    padding: 0 30px;
    margin-bottom: 50px;
    img {
      width: 100%;
    }
  }

  @media (min-width: 920px){
    width: 600px;
  }

  @media (min-width: 1080px){
    width: 1020px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: 120px;
    > div:first-child {
      padding: 0;
      margin-bottom: 0;
      width: 70%;
    }
    > div:last-child {
      padding-left: 70px;
      > section:last-child {
        margin-top: 80px;
      }
    }
  }
`

const KioskSection = styled.section`
  padding-bottom: 60px;
  background: #000;
  @media (min-width: 1080px) {
    padding-bottom: 100px;
  }
`;

export default () => {
  return <>
    <MainVideo>
      <video src="https://hashsnap-static.s3.ap-northeast-2.amazonaws.com/file/hashsnap-ar-site/main.mp4" muted autoPlay />
    </MainVideo>

    <TextBlock
      id="intro"
      fontNoto={true}
      title="오프라인 이벤트 · 테마파크에 AR 키오스크를 활용하세요."
      paragraph={`해시스냅은 AR 콘텐츠를 키오스크에 활용해 촬영 후 사진 인화가 가능한 솔루션입니다.\n솔루션을 활용해 방문객들에게 브랜드 홍보 효과와 즐거운 추억을 선사해보세요.`}
      style={{paddingBottom: 50, textAlign: 'center', background: '#000'}}
    />

    <section style={{background: '#000'}}>
      <Fade>
        <div className="section-container">
          <img src="https://hashsnap-static.s3.ap-northeast-2.amazonaws.com/file/hashsnap-ar-site/pointBg.jpg" alt="" />
        </div>
      </Fade>
    </section>

    <TextBlock
      id="about"
      title="HASHSNAP AR SOLUTION"
      paragraph="키오스크에 AR 기술을 접목해 유명 연예인 및 다양한 캐릭터 등과 함께 촬영 및 인화 가능한 솔루션입니다."
      style={{textAlign: 'center', paddingBottom: 40}}
    >
    </TextBlock>
    
    <PointSection>
      <div>
        <Fade>
          <img src="https://hashsnap-static.s3.ap-northeast-2.amazonaws.com/file/hashsnap-ar-site/pororo_ar2.jpg" />
        </Fade>
      </div>
      <div>
        <TextBlock
          id="branding"
          textSmall={true}
          fontNoto={true}
          title="브랜드 인지도 향상"
          paragraph={`키오스크 기기 및 영상을 통해 브랜드 커스터마이징이 가능하며, 티켓 출력 대기 시간에 숏광고를 삽입해 브랜드 인지도를 높일 수 있습니다.`}
          style={{textAlign: 'left', padding: 0}}
        />
        <TextBlock
          id="induce"
          textSmall={true}
          fontNoto={true}
          title="고객참여 유도"
          paragraph={`고객이 직접 참여해 가상의 캐릭터와 촬영하고 인화할 수 있어 참여를 쉽게 유도할 수 있습니다.`}
          style={{textAlign: 'left', padding: 0}}
        />
      </div>
    </PointSection>
  
    <TextBlock
      id="process"
      title="HASHSNAP PROCESS"
      paragraph="해시스냅 프로세스에서 당사와 함께 마케팅 기획부터 실행까지 맞춤형 컨설팅을 제공합니다"
      style={{textAlign: 'center', background: '#000'}}
    >
      <div className="section-container">
        <video src="https://hashsnap-static.s3.ap-northeast-2.amazonaws.com/file/hashsnap-ar-site/ar_video.mp4" muted controls />
      </div>
    </TextBlock>

    <KioskSection>
      <Fade>
        <div className="section-container">
          <img src="https://hashsnap-static.s3.ap-northeast-2.amazonaws.com/file/hashsnap-ar-site/kiosk_ar.jpg" alt="" />
        </div>
      </Fade>
    </KioskSection>

    <Contact
      id="contact"
      title="CONTACT US"
      subtitle={`해시스냅 AR 솔루션을 활용해 효과적이고 차별화된 브랜드 홍보를 시도해보세요.\n(빠른 문의 : 02-6203-8600 / 김민재 커뮤니케이션 팀장)`}
    />
  </>
}
