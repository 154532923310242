import axios from 'axios';

const REGEX_EMAIL = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const SEND_EMAIL_API = 'https://m4na165xf4.execute-api.ap-northeast-2.amazonaws.com/api/raw';

export default ({ name, address, to, subject, message }) => axios.post(SEND_EMAIL_API, {
  data: 
`From: ${ name } <${ address }>
To: ${ to }
Subject: ${ subject } 
MIME-Version: 1.0
Content-Type: text/html; charset=utf-8

<section>${ message }</section>
`
});