import React from "react"
import styled from "styled-components"
import Fade from 'react-reveal/Fade'

const TextBlock = ({ textSmall = false, fontNoto = false, title, paragraph, children, id, ...props }) => {
  return (
    <TextBlockWrapper id={id} textSmall={textSmall} fontNoto={fontNoto} {...props}>
      <Fade bottom>
        <div>
          <h2>{title}</h2>
          <p>{paragraph}</p>
          {children}
        </div>
      </Fade>
    </TextBlockWrapper>
  )
}

const TextBlockWrapper = styled.section`
  color: #fff;
  padding: 60px 20px;
  /* background: linear-gradient(45deg, #060c21, #0d0139); */

  @media (min-width: 768px) {
    padding: 120px 40px;
  }

  h2 {
    margin-bottom: 30px;
    background: -webkit-linear-gradient(45deg, #f441a5, #03a9f4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
    letter-spacing: 0.05rem;
    font-weight: bolder;
    font-family: ${props => props.fontNoto ? 'Noto Sans KR, sans-serif' : 'Poppins, sans-serif'};
    ${props => props.textSmall 
    ? `font-size: 1.5rem;` 
    : `font-size: 2.2rem;`
    };
    @media (min-width: 1024px) {
      ${props => props.textSmall 
      ? `font-size: 2rem;` 
      : `font-size: 3.5rem;`
      };
    }
  }

  p {
    margin-bottom: 40px;
    text-shadow: 0px 0px 5px rgba(8, 0, 8, 1);
    opacity: 0.8;
    font-weight: 400;
    font-family: 'Noto Sans KR', sans-serif;
    ${props => props.textSmall 
    ? `font-size: 1.05rem;
      line-height: 1.8rem;` 
    : `font-size: 1.25rem;
      line-height: 2.1rem;` 
    };
    @media (min-width: 1024px) {
      white-space: pre-line;
      ${props => props.textSmall 
      ? `font-size: 1.2rem; 
        line-height: 1.9rem;` 
      : `font-size: 1.5rem; 
        line-height: 2.25rem;`
      };
    }
  }
`

export default TextBlock
