import {Link} from "gatsby"
import React, {useRef} from "react"
import styled from "styled-components"
import Button from "./button"
import sendMail from '../helper/aws-email'
import Fade from 'react-reveal/Fade'
import Swal from 'sweetalert2'
import 'formdata-polyfill';

const handleSendEmail = (target) => async(ev) => {
  ev.preventDefault();
  const form = new FormData(target);
  const company = form.get("company");
  const name = form.get("name");
  const phone = form.get("phone");
  const email = form.get("email");
  const message = form.get("message");
  Swal.fire({
    text: '문의 발송 중...',
    showConfirmButton: false
  });
  
  try{
    await sendMail({
      name,
      address: "info@hashsnap.net",
      to: "info@hashsnap.net",
      subject: "해시스냅 AR 솔루션 문의",
      message: `
      <p>회사명 : ${company}</p>
      <p>담당자 : ${name}</p>
      <p>연락처 : ${phone}</p>
      <p>이메일 : ${email}</p>
      <p>문의사항 : ${message}</p>
      `
    });
    
    Swal.fire({
      icon: 'success',
      text: '문의가 성공적으로 발송되었습니다.'
    })

  }catch(err){
    Swal.fire({
      icon: 'error',
      text: '오류 발생. 다시 시도해 주세요',
    });
  }
}

const Contact = ({ title, subtitle, id }) => {
  const formEl = useRef();
  return (
    <ContactWrapper id="contact">
      <h2>{title}</h2>
      <p>{subtitle}</p>
      <Fade bottom>
        <div className="content-container">
          <form ref={formEl} name="contact" onSubmit={handleSendEmail(formEl.current)}>
            <div className="input-area">
              <input
                type="text"
                name="company"
                aria-label="Company"
                required
                autoComplete="off"
              />
              <label className="label-name" htmlFor="company">
                <span className="content-name">회사명</span>
              </label>
            </div>

            <div className="input-area">
              <input
                type="text"
                name="name"
                aria-label="Name"
                autoComplete="off"
                required
              />
              <label className="label-name" htmlFor="name">
                <span className="content-name">이름</span>
              </label>
            </div>

            <div className="input-area">
              <input
                type="text"
                name="phone"
                aria-label="Phone"
                autoComplete="off"
                required
              />
              <label className="label-name" htmlFor="phone">
                <span className="content-name">연락처</span>
              </label>
            </div>

            <div className="input-area">
              <input
                type="email"
                name="email"
                aria-label="Email"
                autoComplete="off"
                required
              />
              <label className="label-name" htmlFor="email">
                <span className="content-name">이메일</span>
              </label>
            </div>

            <div className="input-area">
              <textarea
                type="text"
                name="message"
                rows="8"
                aria-label="Message"
                autoComplete="off"
                required
              />
              <label className="label-name" htmlFor="message">
                <span className="content-name">문의사항</span>
              </label>
            </div>
            <div className="checkbox-area">
              <input
                type="checkbox"
                name="policy"
                id="policy"
                aria-label="Policy"
                autoComplete="off"
                required
              />
              <label className="label-name" htmlFor="policy">
                <span className="content-name">개인정보 처리방침에 동의합니다.</span>
              </label>
              <Link to="/policy" className="link" target="_blank">내용보기</Link>
            </div>

            <div className="input-area button-area">
              <Button label="Send Contact Form" cta="문의하기" type="submit" />
            </div>
          </form>
        </div>
      </Fade>
    </ContactWrapper>
  )
}

const ContactWrapper = styled.section`
  padding: 50px 25px;
  text-align: center;
  overflow: hidden;
  @media (min-width: 768px) {
    padding: 100px 40px;
  }
  h2 {
    background: -webkit-linear-gradient(45deg, #f441a5, #03a9f4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
    letter-spacing: 0.05rem;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 2.2rem;
    font-weight: bolder;

    @media (min-width: 1024px) {
      font-size: 3.5rem;
    }
  }
  p {
    margin-bottom: 2rem;
    text-align: center;
    text-shadow: 0px 0px 5px rgba(8, 0, 8, 1);
    opacity: 0.8;
    white-space: pre-line;
    font-weight: 400;
    font-family: 'Noto Sans KR', sans-serif;
    font-size: 1.05rem;
    line-height: 1.8rem;
    @media (min-width: 1024px) {
      font-size: 1.2rem;
      line-height: 1.9rem;
    }
  }

  .content-container {
    width: 100%;
    margin: 0 auto;
    form {
      position: relative;
      overflow: hidden;

      .input-area {
        margin-bottom: 40px;
        position: relative;

        &.button-area {
          text-align: center;
          margin-bottom: 0;
          margin-top: 50px;
        }

        label {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          pointer-events: none;

          &::after {
            content: "";
            position: absolute;
            left: 0px;
            bottom: -1px;
            height: 1px;
            background: linear-gradient(90deg, #f441a5, #03a9f4);
            width: 100%;
            transform: translateX(-100%);
            transition: transform 0.3s ease;
          }
        }

        .content-name {
          position: absolute;
          top: 15px;
          left: 20px;
          transition: all 0.3s ease;
          text-transform: uppercase;
          font-family: 'Noto Sans KR', sans-serif;
          font-size: 1rem;
        }
      }

      input[type="text"],
      input[type="email"],
      textarea {
        height: 100%;
        font-family: 'Noto Sans KR', sans-serif;
        font-size: 1rem;
        letter-spacing: 0.05rem;
        padding: 35px 20px 15px;
        display: block;
        width: 100% !important;
        border: none;
        background-color: #0b132e;
        color: #fff;
        position: relative;
        box-sizing: border-box;
        outline: none;

        &:focus,
        &:valid {
          padding-top: 35px;
          padding-bottom: 15px;
          + .label-name {
            .content-name {
              transform: translateY(-25%);
              font-size: 0.8rem;
              opacity: 0.4;
            }
            &::after {
              transform: translateX(0%);
            }
          }
        }
      }

      input[type="checkbox"] {
        width: 18px;
        height: 18px;
        margin-right: 15px;
      }

      .checkbox-area {
        margin-bottom: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
        .content-name {
          color: #fff;
          font-family: 'Noto Sans KR', sans-serif;
          font-size: 1rem;
        }
        .link {
          margin-left: auto;
          text-decoration: none;
          color: #fff;
          padding: 8px 16px;
          font-size: 0.9rem;
          border-radius: 5px;
          background: #0b132e;
          white-space: nowrap;
        }
      }
    }
  }
`

export default Contact
